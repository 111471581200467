import React, { useEffect, useState } from "react"

import Header from "../components/Header"
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis"
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import ReactDataSheet from 'react-datasheet';
import MoneyIcon from '@mui/icons-material/Money';
import 'react-datasheet/lib/react-datasheet.css'
import { DataGrid } from '@mui/x-data-grid';
import {
    Container,
    Grid,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    Box,
    Avatar,
    TextField,
    Button,
} from "@mui/material"
import { updateExpenses, fetchExpenseModes, fetchExpenses } from "../services"
import { useQuery } from "react-query"
import Select from "react-select"
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import Spinner from "../components/Spinner";

const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
});

function Expenses() {
    const [expenses, setExpenses] = useState([]),
    [totalExpenses, setTotalExpenses] = useState(0),
    [date, setDate] = useState()

    const {data, isLoading: isExpensesLoading } = useQuery("expenses", fetchExpenses, {
        // select: data => data.expenses
        onSuccess: (data) => {
            // Compute total
            let sum = data.expenses.reduce((sum, a) => sum + a.amount, 0);
            setTotalExpenses(sum)
            setExpenses(data.expenses)
        },
        refetchOnWindowFocus: false
    })

    const { data: modes, isLoading } = useQuery("modes", fetchExpenseModes, {
        select: (data) => data.modes.map((m) => {
            return { label: m.name_ar, value: m.id }
        }),
        refetchOnWindowFocus: false
    })

    const styles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "transparent"

        }),
        menu: (base) => ({
            ...base,
            background: "#1f2937"
        }),
        option: (base, { data, isDisabled, isFocused, isSelected, hasValue }) => ({
            ...base,
            color: "#FFF",
            backgroundColor: isFocused || isSelected ? "#3b82f6" : "#1f2937",
          })
    }

    const columns = [
        {
            field: 'amount',
            headerName: 'المبلغ',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
                `${params.row.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''} ج.م`,
        },
        {
            field: 'mode_name_ar',
            headerName: 'الفئة',
            sortable: false,
            width: 160,
        },
        {
            field: 'description',
            headerName: 'الوصف',
            sortable: false,
            flex: 1,
        },
    ]

    /*const [grid, setGrid] = useState([
        [{ value: "المبلغ", width: "15%", className: "wide-cell bold", readOnly: true }, { value: "الفئة", width: "35%", className: "wide-cell bold", readOnly: true }, { value: "الوصف", width: "85%", className: "wide-cell bold", readOnly: true}],
        [{ value: "250.00", className: "wide-cell" }, { value: "مرتبات", className: "wide-cell" }, { value: "سلفة فاطمة", className: "wide-cell"}],
        [{ value: "1000.00", className: "wide-cell" }, { value: "دفعة مصنع", className: "wide-cell" }, { value: "دفعة ابو عميرة", className: "wide-cell" }],
        [{ value: "", className: "wide-cell" }, { className: "wide-cell", component: (
            <Select 
                options={modes}
                placeholder="اختر الفئة"
            />
        ) }, { value: "", className: "wide-cell" }],
    ])*/

    const saveExpenses = async (data) => {
        /*try {
            // Remove first row
            let clonedData = [...data]
            clonedData.splice(0, 1)
            await updateExpenses(clonedData)
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
            console.log(e)
        }*/
        try {
            await updateExpenses(data)
            window.location.reload()
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
            console.log(e)
        }
    }

    useEffect(() => {
        let todaysDate = moment().format("YYYY-M-D dddd")
        setDate(todaysDate)
    }, [])

    if (isLoading || isExpensesLoading) return <Spinner />
    return (
        <CacheProvider value={cacheRtl}>
            <Header />

            <Container disableGutters>
                <h2 sx={{ mb: 5 }}>مصاريف يوم {date}</h2>

                <Formik
                  initialValues={{
                    amount: "",
                    mode_id: "",
                    description: "",
                  }}
                  validationSchema={Yup.object().shape({
                    amount: Yup.number().required("برجاء ادخال المبلغ"),
                    mode_id: Yup.number().required(),
                    description: Yup.string().required("برجاء ادخال الوصف / الملاحظات"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      saveExpenses(values);
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                <Grid container direction="row" spacing={3}>
                    <Grid item lg={2}>
                        <TextField
                            name="amount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth={true}
                            type="number"
                            value={values.amount}
                            size="small"
                            variant="outlined"
                            placeholder="أدخل المبلغ"
                            error={errors.amount && touched.amount}
                            helperText={
                                errors.amount && touched.amount
                                ? errors.amount
                                : null
                            }
                        />
                    </Grid>

                    <Grid item lg={3}>
                        <Select
                            name="mode_id"
                            fullWidth={true}
                            options={modes}
                            defaultValue={modes.find((d) => {
                                return d.value === values.id
                                  ? d.id
                                  : null;
                              })}
                              onChange={({ value }) => {
                                setFieldValue("mode_id", value);
                              }}
                              onBlur={handleBlur}
                            placeholder="اختر الفئة"
                            styles={styles}
                        />
                    </Grid>

                    <Grid item lg={6}>
                        <TextField
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            fullWidth={true}
                            multiline={true}
                            size="small"
                            variant="outlined"
                            placeholder="الوصف / الملاحظات"
                            error={errors.description && touched.description}
                            helperText={
                                errors.description && touched.description
                                ? errors.description
                                : null
                            }
                        />
                    </Grid>

                    <Grid item lg={1}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="medium"
                            disabled={isSubmitting}
                        >اضافة</Button>
                    </Grid>
                </Grid>
                </form>
                )}
                </Formik>

                <Grid container direction="row" spacing={5}>
                    <Grid item lg={7}>
                        <h2>&nbsp;</h2>
                        {/* <Paper elevation={0}> */}
                        {/* <ReactDataSheet
                                data={grid}
                                valueRenderer={cell => cell.value}
                                onCellsChanged={changes => {
                                    const newGrid = grid.map(row => [...row]);
                                    changes.forEach(({ cell, row, col, value }) => {
                                        newGrid[row][col] = { ...newGrid[row][col], value };
                                    });
                                    setGrid( newGrid );
                                    saveExpenses(newGrid)
                                }}
                            /> */}
                        {/* </Paper> */}

                        <Paper elevation={0}>
                            <DataGrid
                                rows={expenses}
                                columns={columns}
                                hideFooter={true}
                                style={{ height: "65vh" }}
                            />
                        </Paper>
                    </Grid>

                    <Grid item lg={1} />

                    <Grid item lg={4}>
                        <h2>&nbsp;</h2>
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                        >
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#ffa733" }} color={"primary"}>
                                        <MoneyIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="إجمالي المصروفات"></ListItemText>
                            </ListItem>
                            <Divider component="li" />

                            <Box ml={2}>
                                <h1>{totalExpenses.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</h1>
                            </Box>
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </CacheProvider>
    )
}

export default Expenses