import React, {useState, useEffect} from "react"

import Header from "../components/Header"
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis"
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { DataGrid } from '@mui/x-data-grid'
import { useQuery } from "react-query"
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from '@mui/material/IconButton';
import { styled } from "@mui/material/styles";
import MoneyIcon from '@mui/icons-material/Money';
import CloseIcon from "@mui/icons-material/Close";
import {
    fetchReceipts
} from "../services"
import {
    Container,
    CircularProgress,
    Grid,
    Divider,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Box,
    Avatar,
    Button,
    Badge,
    Chip,
    Link,
} from "@mui/material"
import Spinner from "../components/Spinner";

const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function Reports () {
    const [activeInvoice, setActiveInvoice] = useState(null),
    [date, setDate] = useState(null),
    [sales, setSales] = useState([]),
    [modal, setModal] = useState(false),
    [totalSales, setTotalSales] = useState(0),
    [groupedSales, setGroupedSales] = useState([])

    const columns = [
        {
            field: 'invoice_no',
            headerName: '# الفاتورة',
            flex: 0.5,
            renderCell: (params) => {
                return <Link href="javascript:void(0)" onClick={() => {
                    setActiveInvoice(params.row)
                    setModal(true)
                }}>{params.row.invoice_no}</Link>
            }
        },
        {
            field: 'type',
            headerName: 'النوع',
            flex: 0.5,
            renderCell: (params) => {
                return <Chip variant="outlined" label={undefined !== params.row.price_diff ? `استبدال` : `فاتورة`} color={undefined !== params.row.price_diff ? `info` : `success`} size="small" />
            }
        },
        {
            field: 'customer_name',
            headerName: 'العميل',
            width: 160,
        },
        {
            field: 'n_items',
            headerName: 'العدد',
            flex: 0.3,
            renderCell: (params) => {
                return <span>{Number(params.row.price_diff) ? "-" : params.row.items.length}</span>
            }
        },
        {
            field: 'total',
            headerName: 'المبلغ المدفوع',
            renderCell: (params) => {
                let amount = undefined !== params.row.price_diff ? params.row.price_diff : params.row.total
                return <span>{amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</span>
            },
            flex: 0.5,
        },
        {
            field: 'discounted',
            headerName: 'اجمالي الخصم',
            renderCell: (params) => {
                return <Chip color={params.row.discounted ? `error` : `default`} size="small" label={`${params.row.discounted.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م`} />
            },
            flex: 0.5,
        },
        {
            field: 'payment_method_name_ar',
            headerName: 'طريقة الدفع',
            flex: 0.5,
            renderCell: (params) => {
                return <Chip color={params.row.payment_method_id == 1 ? `success` : `warning`} label={params.row.payment_method_name_ar} size="small" />
            }
        },
        {
            field: "actions",
            headerName: "الاجراءات",
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Button
                        color={"primary"}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            setActiveInvoice(params.row)
                            setModal(true)
                        }}
                    >
                        التفاصيل
                    </Button>
                )
            }
        }
    ]

    let d = new Date()
    d.setDate(d.getDate() - 5)

    // const { data, isLoading } = useQuery("sales", () => fetchReceipts({ startDate: moment(d).format("yyyy-MM-DD"), endDate: moment(new Date()).format("yyyy-MM-DD") }), {
        const { data, isLoading } = useQuery("sales", () => fetchReceipts({ startDate: moment(new Date()).format("yyyy-MM-DD"), endDate: moment(new Date()).format("yyyy-MM-DD") }), {
        select: (data) => data.sales,
        onSuccess: (data) => {
            let sum = data.reduce((sum, a) => sum + (undefined !== a.price_diff ? a.price_diff : a.total), 0),
            results = []

            data.forEach((o) => {
                if (!results.some(ev => ev.payment_method_id === o.payment_method_id))
                    results.push({ payment_method_id: o.payment_method_id, total: 0, payment_method_name_ar: o.payment_method_name_ar })
            })
        
            results.forEach((entry) => {
                entry.total = data.reduce((sum, a) => a.payment_method_id === entry.payment_method_id ? sum + (undefined !== a.price_diff ? a.price_diff : a.total) : sum + 0, 0)
            })
            setTotalSales(sum)
            setGroupedSales(results)
        },
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        let todaysDate = moment().format("YYYY-M-D dddd")
        setDate(todaysDate)
    }, [])

    /*useEffect(() => {
        if (activeInvoice) {
            setModal(true)
        }
    }, [activeInvoice])*/

    
    if (isLoading) return <Spinner />

    return (
        <CacheProvider value={cacheRtl}>
            <Header />

            <BootstrapDialog
                onClose={() => {
                    // setActiveInvoice(null)
                    setModal(false)
                }}
                open={modal}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    تفاصيل فاتورة رقم {activeInvoice?.invoice_no} - {activeInvoice?.customer_name}&nbsp;&nbsp;{activeInvoice?.discount_id ? <Chip label={activeInvoice?.discount_name_ar} color={"success"} size="small" /> : ""}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setModal(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    <Grid container direction="row" spacing={5}>
                        <Grid item lg={12}>

                            <table style={{ width: "100%", textAlign: "right", borderCollapse: "collapse" }}>
                                <thead>
                                    <tr style={{ height: 50, color: "#18bdca" }}>
                                        <th>الأرتكل</th>
                                        <th>اللون</th>
                                        <th>المقاس</th>
                                        <th>السعر قبل الخصم</th>
                                        <th>السعر بعد الخصم</th>
                                        <th>اجمالي الخصم</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        activeInvoice?.items.map((item, rowIndex) => (
                                            <tr key={rowIndex} style={{ height: 40, fontSize: 12}} className={item.is_refunded ? `refunded-row` : ``}>
                                                <td>{item.sku}</td>
                                                <td>{item.color_ar}</td>
                                                <td>{item.size}</td>
                                                <td>{Number(item.new_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</td>
                                                <td>{Number(item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</td>
                                                <td>
                                                    {Number(item.new_price - item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م
                                                    {item.is_refunded ? <Chip label={item.is_refunded ? `مرتجع` : `-`} color={item.is_refunded ? `error` : `default`} size="small" sx={{ ml: 2 }} /> : ""}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <Container disableGutters>
                <h2 sx={{ mb: 5 }}>مبيعات يوم {date}</h2>

                <Grid container direction="row" spacing={5}>
                    <Grid item lg={9}>
                        <Paper elevation={0}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                // hideFooter={true}
                                style={{ height: "80vh" }}
                            />
                        </Paper>
                    </Grid>
                    
                    <Grid item lg={3}>
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                        >
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#ffa733" }} color={"primary"}>
                                        <MoneyIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="إجمالي المبيعات"></ListItemText>
                            </ListItem>
                            <Divider component="li" />

                            <Box ml={2}>
                                <h1>{totalSales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</h1>

                                {
                                    groupedSales.map((p, gIndex) => (
                                        <Box key={gIndex} sx={{ mb: 2 }}>
                                            <Divider>{p.payment_method_name_ar}</Divider>
                                                <h5>{p.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</h5>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </List>
                    </Grid>
                </Grid>
                
            </Container>
        </CacheProvider>
    )
}

export default Reports