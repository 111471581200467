import React, { useState } from "react"
import rtlPlugin from "stylis-plugin-rtl";
import { v4 as uuidv4 } from 'uuid'
import { Formik } from 'formik'
import { useAuth } from "../utils/AuthContext"
import Cookies from "js-cookie"
import { login } from "../services/user"

import {
    Grid,
    Button,
    Box,
    CssBaseline,
    TextField
} from '@mui/material';

import { LoadingButton } from "@mui/lab"

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";

const cacheRtl = createCache({
    key: "muirtl",
    // prefixer is the only stylis plugin by default, so when
    // overriding the plugins you need to include it explicitly
    // if you want to retain the auto-prefixing behavior.
    stylisPlugins: [prefixer, rtlPlugin]
});


function Login () {
    // const [loading, setLoading] = useState(false)
    const { setAuth } = useAuth()
    const performLogin = async (data) => {
        try {
            let res = await login({ ...data, uuid: uuidv4() })
            Cookies.set("token", res.accessToken)
            setAuth(true)
            window.location.href = res.redirect_url
        } catch (e) {
            console.log(e)
            alert(e.data?.message)
        }
    }

    return (
        <CacheProvider value={cacheRtl}>
        <CssBaseline />
        <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={4} />
            <Grid item lg={4} md={4} sm={4}>
                <div style={{padding: "50px 0px"}} className="is-flex fdc has-text-centered">
                    <Box mb={5}>
                        {/* <img src={`${process.env.PUBLIC_URL}images/logo-new-cropped.png`} alt="Moudy" height={200} /> */}
                        <img src={`${process.env.PUBLIC_URL}images/logo-white-cropped.png`} alt="Moudy" height={200} />
                    </Box>

                    <h1 className="text-m">تسجيل الدخول</h1>

                    <Formik
                        initialValues={{username: "", password: ""}}
                        onSubmit={(values, { setSubmitting }) => {
                            performLogin(values)
                            setSubmitting(false)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form onSubmit={ handleSubmit } className="is-flex fdc">
                                <Box mb={5}>
                                    <TextField
                                        fullWidth={true}
                                        type="text"
                                        name="username"
                                        label="إسم المستخدم"
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                    {errors.username && touched.username && errors.username}
                                </Box>

                                <Box mb={5}>
                                    <TextField
                                        fullWidth={true}
                                        type="password"
                                        name="password"
                                        label="كلمة المرور"
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                    {errors.password && touched.password && errors.password}
                                </Box>

                                <LoadingButton
                                    type="submit"
                                    size={"large"}
                                    variant="contained"
                                    // loading={loading}
                                    loadingPosition="end"
                                >تسجيل الدخول</LoadingButton>
                            </form>
                        )}
                    </Formik>
                </div>
            </Grid>
            <Grid item lg={4} md={4} sm={4} />
        </Grid>
        </CacheProvider>
    )
}

export default Login