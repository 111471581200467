import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const login = async (data) => {
    return request({
        url: `${BaseUrl}v1/login`,
        method: "POST",
        data
    }, true)
}

export const me = async () => {
    return request({
        url: `${BaseUrl}v1/me`
    }, true)
}

export const fetchBranchInfo = async () => {
    return request({
        url: `${BaseUrl}v1/branches`
    }, true)
}

export const addCustomer = async (data) => {
    return request({
        url: `${BaseUrl}v1/customer`,
        method: "POST",
        data
    }, true)
}

export const submitPurchase = async (data) => {
    return request({
        url: `${BaseUrl}v1/purchase`,
        method: "POST",
        data
    }, true)
}

export const fetchPaymentMethods = async () => {
    return request({
        url: `${BaseUrl}v1/payment_methods`,
        method: "GET",
    }, true)
}

export const fetchDiscounts = async (id=null, active=null) => {
    return request({
        url: `${BaseUrl}v1/discounts${id ? `?id=${id}` : ``}${active ? (id ? `&active=1` : `?active=1`) : ``}`,
        method: "GET",
    }, true)
}

export const searchCustomers = async (hint) => {
    return request({
        url: `${BaseUrl}v1/customers?phone_number=${hint}`,
        method: "GET"
    }, true)
}

export const computeCartTotal = async (items, discountId) => {
    return request({
        url: `${BaseUrl}v1/compute-cart`,
        method: "POST",
        data: { items, discountId }
    }, true)
}