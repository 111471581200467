import React, { useEffect } from "react"
import {
    Typography
} from "@mui/material"
import { Grid, Container, Box, Button } from "@mui/material"
import {
    Link,
    useLocation
} from "react-router-dom"
import { signout } from "../utils"

function Header () {
    const location = useLocation()
    
    return (
        <Container disableGutters>
            <div style={{padding: "20px 0px", marginBottom: 10}} className="is-flex aic">
                <Box mr={10}>
                    <a href="/">
                        {/* <img src={`${process.env.PUBLIC_URL}images/logo-new-cropped.png`} height={80} alt="Moudy" /> */}
                        <img src={`${process.env.PUBLIC_URL}images/logo-white-cropped.png`} height={70} alt="Moudy" />
                    </a>
                </Box>

                <div className="header-links">
                    <Box mr={5}>
                        <Link to="/" className={`${location.pathname == "/" ? `active` : ``}`}>فاتورة جديدة</Link>
                    </Box>

                    <Box mr={5}>
                        <Link to="/expenses" className={`${location.pathname == "/expenses" ? `active` : ``}`}>المصاريف</Link>
                    </Box>

                    <Box mr={5}>
                        <Link to="/warehouse" className={`${location.pathname == "/warehouse" ? `active` : ``}`}>المخزن</Link>
                    </Box>

                    <Box mr={5}>
                        <Link to="/exchange" className={`${location.pathname == "/exchange" ? `active` : ``}`}>الفواتير</Link>
                    </Box>

                    <Box mr={5}>
                        <Link to="/reports" className={`${location.pathname == "/reports" ? `active` : ``}`}>التقارير</Link>
                    </Box>

                    <Box mr={5}>
                        <Button
                            onClick={() => signout()}
                            variant="outlined" color="error"
                        >
                            الخروج
                        </Button>
                    </Box>
                </div>
            </div>
        </Container>
    )
}

export default Header